<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_itemMastaList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="setSupplier()"
                :rules="[rules.supplierRequired]"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 品番 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="itemSelected"
                :label="$t('label.lbl_productCode')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 付帯品番 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="accessoryProduct"
                :label="$t('label.lbl_accessoryProduct')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
          </v-row>
          <v-row>
            <!-- 基準日-->
            <div class="date-style first-search-item">
              <v-text-field
                outlined
                dense
                v-model="referenceDate"
                class="txt-single date-style"
                :label="$t('label.lbl_refernceDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="referenceDateCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 仮登録 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="temporaryRegist"
                :items="temporaryRegistList"
                :label="$t('label.lbl_tempoRegist')"
                class="txt-single"
                persistent-hint
                :hint="seTtemporaryRegist()"
                margin-bottom="0"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer"></span>
            <!-- 詳細情報 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="detailsInfo"
                :items="detailsInfoList"
                :label="$t('label.lbl_dateilsInfo')"
                class="txt-single"
                persistent-hint
                :hint="setDetailsInfo()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer"></span>
            <!-- 単価Gr -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="unitpriceGr"
                :items="unitpriceGrList"
                :label="$t('label.lbl_unitPriceGr')"
                class="txt-single"
                persistent-hint
                :hint="setUnitpriceGr()"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="getItemList('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
              </div>
              <div class="btn-area">
                <!--コピーボタン-->
                <v-btn
                  v-if="btnToggle"
                  color="primary"
                  id="btn-search"
                  class="api-btn"
                  @click="copy"
                  >{{ $t("btn.btn_copy") }}</v-btn
                >
                <!--新規登録ボタン-->
                <v-btn color="primary" id="btn-search" class="api-btn" @click="initRegist">{{
                  $t("btn.btn_iniRegist")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              style="transform: scale(2)"
            />
          </template>
          <!-- 詳細ボタン -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="openUpdateDelete(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <!-- 商品コード・付帯品番 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productCnCd.length > 35">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                    {{ item.productCnCd }}<br />
                    {{ item.accessoryProductCd }}
                  </div>
                </template>
                <div>{{ item.productCnCd }}<br />{{ item.accessoryProductCd }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.productCnCd }}<br />
              {{ item.accessoryProductCd }}
            </div>
          </template>
          <!--品名-->
          <template v-slot:[`item.productName`]="{ item }">
            <div v-if="item.productName.length > 35">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 200px" v-bind="attrs" v-on="on">
                    {{ item.productName }}
                  </div>
                </template>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.productName }}</div>
            </div>
          </template>
          <!-- 仮登録 -->
          <template v-slot:[`item.tempoRegist`]="{ item }">
            <div v-if="item.tempoRegist == '-'" style="color: black">
              <template>
                <div>
                  {{ item.tempoRegist }}
                </div>
              </template>
            </div>
            <div v-else-if="item.tempoRegist == '要登録'" style="color: red">
              {{ item.tempoRegist }}
            </div>
          </template>
        </v-data-table>
        <!-- 設定ボタン押下後エラー時出現エラー -->
        <v-dialog v-model="copyDialogMsg" :max-width="800">
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ alertMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="okClicked"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_MST_ITEM_MASTER_LIST,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // 商品詳細情報登録画面ポップアップ
    openDatailsAdd: false,
    u_10: false,
    // 検索ボタン押下後、trueにする
    // ボタン表示の切り替え
    btnToggle: false,
    // 入力チェック結果フラグ
    isInputCheck: true,
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // メニュー
    openMenu: null,
    // 基準日
    referenceDate: "",
    referenceDateCal: "",
    dateMenu: false,
    // 品番
    itemSelected: "",
    //付帯品番
    accessoryProduct: "",
    // 仮登録プルダウン
    temporaryRegist: "",
    temporaryRegistList: [],
    // 詳細情報プルダウン
    detailsInfo: "",
    detailsInfoList: [],
    // 単価Grプルダウン
    unitpriceGr: "",
    unitpriceGrList: [],
    // 取引先
    supplierList: [],
    suppliersSelected: "",
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [1, 2],
    // テーブルフッター項目
    inputList: [],
    // コピーリスト
    copyList: [],
    // コピー時アラートダイアログ
    copyDialogMsg: false,
    alertMessage: "",
    // ソート
    sortItem: "",
    sortOptions: {},
    // テーブルヘッダ項目
    headerItems: [
      // 選択
      {
        text: "選択",
        value: "check",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 品番/付帯品番
      {
        text: i18n.tc("label.lbl_productCode") + "/" + i18n.tc("label.lbl_accessoryProduct"),
        value: "productCnCd",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 品名
      {
        text: i18n.tc("label.lbl_productCodeName"),
        value: "productName",
        width: "10%",
        align: "left",
        sortable: true,
      },
      // 仮登録
      {
        text: i18n.tc("label.lbl_tempoRegist"),
        value: "tempoRegist",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 詳細情報
      {
        text: i18n.tc("label.lbl_dateilsInfo"),
        value: "dtlInfo",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        align: "center",
        width: "2%",
      },
    ],
  }),
  methods: {
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.getItemList("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.getItemList("get-search");
    },

    // 初期化
    init() {
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.getCustomInfo();

      this.referenceDate = dateTimeHelper.convertJST();
      this.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());

      if (
        this.$route.params.searchFilter &&
        Object.keys(this.$route.params.searchFilter).length !== 0
      ) {
        // 画面遷移前の検索条件をセット
        Object.assign(this, this.$route.params.searchFilter);
        this.getItemList("get-search");
      }
      // 仮登録、詳細情報プルダウンセット
      this.getDummyAPull();
      this.getDetailsPull();
      this.getUnitpriceGrPull();
    },
    // 詳細画面へ
    openUpdateDelete(item) {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_008,
        params: {
          supplier: this.searchFilter.suppliersSelected, //取引先
          itemCd: item.productCnCd, //品番
          detailsInfo: item.dtlInfo, //詳細登録
          temporaryRegist: item.tempoRegist, //仮登録
          referenceDate: this.referenceDate, //基準日
          itemSid: item.productSid, //商品SID
          displayFlg: "2", //どこからの画面遷移か判別させるため
          // 検索条件
          searchFilter: this.searchFilter,
        },
      });
    },
    // コピーボタン押下
    copy() {
      this.copyList = [];
      for (var i = 0; i < this.inputList.length; i++) {
        if (this.inputList[i].check) {
          this.copyList.push(this.inputList[i]);
        }
      }
      // 選択された行が1行じゃない場合、１行のみコピーするよう警告
      if (this.copyList.length != 1) {
        this.copyDialogMsg = true;
        this.alertMessage = i18n.tc("check.chk_copyCnt");
        this.copyList = [];
      }
    },
    okClicked() {
      this.copyDialogMsg = false;
    },
    // 新規登録ボタン押下
    initRegist() {
      if (this.copyList.length == 1) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MST_008,
          params: {
            copyList: this.copyList,
            suppliersSelected: this.suppliersSelected,
            displayFlg: "1",
            // 検索条件
            searchFilter: this.searchFilter,
          },
        });
      } else {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_MST_008,
          params: {
            displayFlg: "1",
            // 検索条件
            searchFilter: this.searchFilter,
          },
        });
      }
    },
    // 検索ボタン押下後、商品マスタ一覧を取得
    getItemList(val) {
      // 入力チェック結果フラグ
      let inputCheckResult = false;

      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するため、このようなコードとなっている。
       */
      if (
        this.$route.params.searchFilter &&
        Object.keys(this.$route.params.searchFilter).length !== 0
      ) {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      // 入力チェックを実施
      if (inputCheckResult) {
        this.btnToggle = true;

        // ローディング画面表示ON
        this.loadingCounter = 1;

        const config = this.$httpClient.createGetApiRequestConfig();

        // 営業所SID（ログインユーザ情報）
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 基準日
        let dateRange = this.referenceDate + " 23:59:59.999";
        config.params.referenceDate = dateTimeHelper.convertUTC(dateRange);
        // 取引先SID
        config.params.clientSid = this.suppliersSelected;
        // 単価Gr
        let unitpriceGr = "";
        // 全て
        if (this.unitpriceGr == "01") {
          unitpriceGr = "00";
          // 未設定
        } else if (this.unitpriceGr == "02") {
          unitpriceGr = "01";
        }
        config.params.priceGr = unitpriceGr;
        // 削除Flg
        config.params.deleteFlg = "0";

        // 品番
        if (this.itemSelected) {
          config.params.itemCd = this.itemSelected;
        }
        //付帯品番
        if (this.accessoryProduct) {
          config.params.accessoryProduct = this.accessoryProduct;
        }
        // 仮登録プルダウン
        if (this.temporaryRegist) {
          config.params.dummyAdd = this.temporaryRegist;
        }
        // 詳細情報プルダウン
        if (this.detailsInfo) {
          config.params.dtlInfo = this.detailsInfo;
        }
        // ページャー処理
        if (val == "get-search") {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          if (this.page >= 2 && this.inputList.length == 0) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
        }
        // ページング
        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }
        // console.debug("getItemList() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.MST_ITEMSEARCH, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // console.debug("getItemList() Response", response);

              // 成功
              if (jsonData.resCom.resComCode == "000") {
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                const list = [];

                jsonData.resIdv.itemsStandard.forEach((row) => {
                  var dummyAdd = "";
                  if (row.dummyAdd == "01") {
                    dummyAdd = "要登録";
                  } else {
                    dummyAdd = "-";
                  }

                  var dtlInfo = "";
                  if (row.dtlInfo == "01") {
                    dtlInfo = "未登録";
                  } else {
                    dtlInfo = "登録済";
                  }

                  list.push({
                    productSid: row.itemStandardSid, //商品Sid
                    productCnCd: row.itemCd.substring(0, 50).trim(), //品番
                    accessoryProductCd: row.itemCd.slice(-50).trim(), //付帯品番
                    productName: row.itemName, //品名
                    tempoRegist: dummyAdd, //仮登録名
                    tempoRejistDiv: row.dummyAdd, //仮登録
                    dtlInfo: dtlInfo, //詳細情報名
                    dtlInfoDiv: row.dtlInfo, //詳細情報
                  });
                });
                this.inputList = list;

                // 検索値保持
                Object.assign(this.searchFilter, {
                  suppliersSelected: this.suppliersSelected, // 取引先SID
                  itemSelected: this.itemSelected, // 品番
                  accessoryProduct: this.accessoryProduct, // 付帯品番
                  referenceDate: this.referenceDate, // 基準日
                  referenceDateCal: this.referenceDateCal, // 基準日カレンダー
                  temporaryRegist: this.temporaryRegist, // 仮登録
                  detailsInfo: this.detailsInfo, // 詳細情報
                  unitpriceGr: this.unitpriceGr, // 単価Gr
                });

                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;

                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;

              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    // 取引先API接続
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // コードマスタ取得：仮登録プルダウン
    getDummyAPull() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 仮登録
      const dummyPull = getParameter.getCodeMst(appConfig.CODETYPE.DUMMY_ADD_DIV);
      Promise.all([dummyPull])
        .then((result) => {
          // 画面の初期値を設定します。
          this.temporaryRegistList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // コードマスタ取得：詳細情報プルダウン
    getDetailsPull() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 仮登録
      const detailsPull = getParameter.getCodeMst(appConfig.CODETYPE.DETAILS_DIV);
      Promise.all([detailsPull])
        .then((result) => {
          // 画面の初期値を設定します。
          this.detailsInfoList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // コードマスタ取得：単価Grプルダウン
    getUnitpriceGrPull() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 仮登録
      const unitpriceGrPull = getParameter.getCodeMst(appConfig.CODETYPE.UNITPRICE_GR);
      Promise.all([unitpriceGrPull])
        .then((result) => {
          // 画面の初期値を設定します。
          this.unitpriceGrList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // ***プルダウンセット処理***
    // 取引先
    setSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    // 詳細情報
    setDetailsInfo() {
      for (var i = 0; i < this.detailsInfoList.length; i++) {
        if (this.detailsInfoList[i].value == this.detailsInfo) {
          return this.detailsInfoList[i].name;
        }
      }
    },
    // 単価Gr
    setUnitpriceGr() {
      for (var i = 0; i < this.unitpriceGrList.length; i++) {
        if (this.unitpriceGrList[i].value == this.unitpriceGr) {
          return this.unitpriceGrList[i].name;
        }
      }
    },
    // 仮登録
    seTtemporaryRegist() {
      for (var i = 0; i < this.temporaryRegistList.length; i++) {
        if (this.temporaryRegistList[i].value == this.temporaryRegist) {
          return this.temporaryRegistList[i].name;
        }
      }
    },
    /**
     * 全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;
        }
        this.isAllSelected = false;
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.referenceDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.referenceDateCal = null;
      }
    },
    // カレンダー
    prevDate() {
      if (this.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.referenceDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.referenceDateCal != toDate) {
        this.referenceDateCal = toDate;
      } else {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDate() {
      if (this.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.referenceDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.referenceDateCal != toDate) {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    referenceDateCal(val) {
      this.referenceDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getItemList("");
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_007_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getItemList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.overflow {
  overflow: hidden;
}
.toatlNum {
  margin-left: 10px;
}
.txt-center {
  margin: auto;
}
.link {
  text-decoration: none;
}
</style>
